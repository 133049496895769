import React, { useEffect } from 'react'
import { RefActiveParticipantsWithAllPlacements, refreshAllDatasets } from '../hooks/DatasetHooks'
import * as libCon from "../community-hats-js-library/Constants"
import * as locCon from "../LocalConstants"
import { Button, Space } from "antd";
import { Typography } from 'antd';
import { RefStoredValue } from '../hooks/GeneralStoreHooks';
import { useNavigate } from 'react-router-dom';
import { DataCollectionStatusCard } from './DashboardCollectionStatus';
import LoadingDatasets from '../components/LoadingDatasets';
import { isNullOrUndefined } from '../community-hats-js-library/utils/generalFunctions';
import { RefSetCurrentPage } from '../hooks/NavigationHooks';


const { Title } = Typography;


function DashboardSingleCollectionStatus() {

  const navigate = useNavigate();

  // Navigation
  const setCurrentPage = RefSetCurrentPage()


  // Participants
  const [status, participants] = RefActiveParticipantsWithAllPlacements()

  const selectedParticipantId = RefStoredValue(locCon.SELECTED_PARTICIPANT)

  useEffect(() => {

    if (isNullOrUndefined(selectedParticipantId)) {
      setCurrentPage(locCon.PAGE_DASHBOARD_EXPIRY_PARTICIPANTS)
    }


  }, [selectedParticipantId, setCurrentPage])


  return (
    <div className="mainContainer">
      <Title level={2}>{`Participant's Data Collection Status`}</Title>
      <Space direction="horizontal" >
        <Button type="primary" onClick={() => navigate(-1)}>Back</Button>
        <Button type="primary" onClick={() => refreshAllDatasets()}>Refresh All</Button>
      </Space>

      <div style={{ marginTop: "5%" }}>
        {
          status === libCon.STATUS_NOT_STARTED || status === libCon.LOADING || participants === null
            ? <LoadingDatasets />
            : status === libCon.ERROR
              ? <div>Error</div>
              : <div>
                {
                  selectedParticipantId in participants
                    ? <DataCollectionStatusCard key={participants[selectedParticipantId][libCon.ID]} participant={participants[selectedParticipantId]} />
                    : <Title level={3}>Participant Not Found</Title>

                }
              </div>
        }
      </div>
    </div>
  )
}

export default DashboardSingleCollectionStatus