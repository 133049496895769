import { useSelector } from "react-redux";
import { getValue, setValue } from "../store/actions/generalStoreActions";

/**
 * Hook for a single user attriburte in the store read only
 */
export function RefStoredValue(id) {

    const value = useSelector(state => state[id])

    return (value);
}

/**
 * Hook for a single user attriburte in the store read only
 */
export function StateStoredValue(id) {

    const value = useSelector(state => state[id])
    const setVal = (val) => setValue(id, val)

    return ([value, setVal]);
}

export const getStoredValue = (key) => {
    let val = getValue(key)
    if (val === "null" || val === "undefined" || val === null || val === undefined)
        val = null
    return (val)
}

export const setStoredValue = (key, value) => {
    setValue(key, value)
}

// Function for filtering Objects
export const filterObject = (obj, predicate) => {

    return (Object.keys(obj)
        .filter(key => predicate(obj[key]))
        .reduce((res, key) => { return Object.assign(res, { [key]: obj[key] }) }, {}))


}