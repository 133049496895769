// Sensor Functions
import * as libCon from "../Constants"

export const getExpiryDaysByBrand = (brand) => {
    switch (brand) {
        case libCon.GOVEE:
        case libCon.KESTREL:
            return 20
        case libCon.HOBO:
            return 180
        default:
            return 20;
    }

}