import { utils, writeFile } from 'xlsx';

export const exportToExcel = (data, fileName = 'Data.xlsx', columnOrder = []) => {
    // If columnOrder is provided, reorder the data accordingly
    if (columnOrder.length > 0) {
      data = data.map(row => {
        const orderedRow = {};
        columnOrder.forEach(col => {
          orderedRow[col] = row[col];
        });
        return orderedRow;
      });
    }
  
    const worksheet = utils.json_to_sheet(data);
  
    // Adjust column widths to fit content
    const columnWidths = data.reduce((widths, row) => {
      Object.keys(row).forEach((key, index) => {
        const valueLength = row[key] ? row[key].toString().length : 10;
        widths[index] = Math.max(widths[index] || 10, valueLength);
      });
      return widths;
    }, []);
  
    worksheet['!cols'] = columnWidths.map(width => ({ wch: width + 2 }));
  
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    writeFile(workbook, fileName);
  };