// Authentication Hooks
import { useEffect } from "react"
import * as locCon from "../LocalConstants"
import { isNullOrUndefined } from "../community-hats-js-library/utils/generalFunctions"
import { differenceInMinutes, formatISO } from "date-fns"
import { getStoredValue, setStoredValue, StateStoredValue } from "./GeneralStoreHooks"

export const checkIsLoggedIn = () => {

    const lastInteraction = getStoredValue(locCon.LAST_INTERACTION)

    if (isNullOrUndefined(lastInteraction))
        return false

    console.log(differenceInMinutes(new Date(), new Date(lastInteraction)))


    return differenceInMinutes(new Date(), new Date(lastInteraction)) < locCon.MAX_IDLE_MINUTES
}

const updateUserInteraction = () => {
    setStoredValue(locCon.LAST_INTERACTION, formatISO(new Date()))
}




export const RefIsLoggedIn = () => {
    const [isLoggedIn, setIsLoggedIn] = StateStoredValue(locCon.IS_LOGGED_IN)


    // Checks every minute
    useEffect(() => {
        // Function to run every minute
        const runEveryMinute = () => {
            setIsLoggedIn(checkIsLoggedIn())
        };

        // Set the interval to run every 60,000 ms (1 minute)
        const intervalId = setInterval(runEveryMinute, 60000);

        // Cleanup the interval when the component is unmounted
        return () => clearInterval(intervalId);
    }, [setIsLoggedIn]); // Empty dependency array means this effect runs once when the component mounts


    // Inactivity Check
    useEffect(() => {

        window.addEventListener('click', () => updateUserInteraction());

        return () => {
            window.removeEventListener('click', updateUserInteraction);
        }
    }, [])

    return isLoggedIn


}