// Global constants

// Parameter names
export const NAME = 'name'
export const VALUE = "value"
export const LABEL = "label"
export const VALUES = "values"
export const LABELS = "labels"
export const X_VALUES = "x_values"
export const Y_VALUES = "y_values" 
export const BARS = "bars"
export const LINES = "lines"
export const BAR_VALUE = "barValue"
export const CONTINUES_LINE_VALUE = "continuesLineValue"
export const CATEGORCIAL_LINE_VALUE = "categoricalLineValue"
export const Y_AXIS_ON_LEFT = "y_axis_on_left"

export const Y_VALUE = "y_value"
export const START_Y_VALUE = "start_y_value"
export const END_Y_VALUE = "end_y_value"


// Global parameters for all charts
// --------------------------------
// All charts
export const MARGIN = "margin"
export const LEFT = "left"
export const RIGHT = "right"
export const BOTTOM = "bottom"
export const TOP = "top"
export const PROPORTIONAL_WIDTH = "proportional_width"
export const PROPORTIONAL_HEIGHT = "proportional_height"
export const X_LABEL_FONT_SIZE = "x_label_font_size"
export const Y_LABEL_FONT_SIZE = "y_label_font_size"
export const Y_LABEL = "y_label"
export const X_LABEL = "x_label"
export const Y_PADDING = 'y_padding'
export const X_PADDING = 'x_padding'
export const SECOND_Y_AXIS = "second_y_axis"
export const SECOND_Y_LABEL = "second_y_label"
export const INCLUDE_BORDER = "include_border"
export const TITLE = "title"
export const TITLE_FONT_SIZE = "title_font_size"

// Line charts
export const NORMAL_WIDTH = 'normal_width'
export const ENFASIS_WIDTH = 'enfasis_width'
export const CONTINUOS_LINE_NAME = "continuos_line_name"
export const CATEGORICAL_LINE_NAME = "categorical_line_name"
export const INCLUDE_CATEGORCIAL_LINE = "include_categorical_line"
export const INCLUDE_CONTINOUS_LINE = "include_continuos_line"
export const CONTINUOS_LINE_COLOR = "continuos_line_color"
export const CATEGORICAL_LINE_COLOR = "categorical_line_color"

// Bar constants
export const BARS_COLOR = "bars_color"
export const BARS_NAME = "bars_name"
export const BARS_MIDPOINT = "bars_midpoint"
export const BARS_POSITIVE_COLOR = "bars_positive_color"
export const BARS_NEGATIVE_COLOR = "bars_negative_color"



// Opacity constants
export const FULL_OPACITY = "full_opacity";
export const HIDDEN_OPACITY = "hidden_opacity";
export const MIN_OPACITY = "min_opacity"
export const BARS_LABEL = "bars_label"


// Legend constants
export const INCLUDE_LEGEND = "include_legend"
export const LEGEND_HEIGHT = "legend_height"
export const LEGEND_SELECT_SHIFT = "legend_select_shift"
export const LEGEND_NUM_ROWS = "legend_num_rows"
export const LEGEND_CIRCLE_RADIUS = "legend_circle_radius"
export const LEGEND_PADDING = "legend_padding"
export const LEGEND_MARGIN_FROM_CHART = "legend_margin_from_chart"
export const ENFASIS_WEIGHT = "enfasis_weight"
export const NORMAL_WEIGHT = "normal_weight"
export const INTERACTIVE_LEGEND = "interactive_legend"
export const LEGEND_MARGIN_LEFT = "legend_margin_left"
export const LEGEND_MARGIN_RIGHT = "legend_margin_right"

// Label constants
export const LABEL_GENERATING_FUNCTION = "label_gen_fun"
export const CATEGORICAL_LABEL_GENERATOR_FUNCTION = "categorical_label_function"

// Animation constants
export const DATA_CHANGE_TRANSITION_TIME = "data_change_transition_time"
export const ANIMATION_TIME = "animation_time"

// Speedometer Constant
export const SPEEDOMETER_START_ANGLE = "speedometer_start_angle"
export const SPEEDOMETER_ANGLE_WIDTH = "speedometer_angle_width"
export const SPEEDOMETER_RADIUS_START = "speedometer_radius_start"
export const SPEEDOMETER_RADIUS_END = "speedometer_radius_end"
export const SPEEDOMETER_MIDDLE_DOT_SIZE = "speedometer_middle_dot_size"
export const SPEEDOMETER_MIDDLE_DOT_COLOR = "speedometer_middle_dot_color"
export const SPEEDOMETER_NEEDLE_PADDING = "speedometer_needle_padding"
export const SPEEDOMETER_NEEDLE_WIDTH = "speedometer_needle_width"
export const SPEEDOMETER_LABELS_PADDING = "speedometer_labels_padding"
export const SPEEDOMETER_LABELS_SIZE = "speedometer_labels_size"
export const SPEEDOMETER_LABELS_COLOR = "speedometer_labels_color"
export const SPEEDOMETER_MAIN_LABEL_PADDING = "speedometer_main_label_padding"
export const SPEEDOMETER_MAIN_LABEL_SIZE = "speedometer_main_label_size"
export const SPEEDOMETER_SECOND_LABEL_PADDING = "speedometer_second_label_padding"
export const SPEEDOMETER_SECOND_LABEL_SIZE = "speedometer_second_label_size"

// Speedometer Data Constants
export const NUMBER_OF_SEGMENTS = "number_of_segments"
export const SEGMENT_IDS = "segment_ids"
export const SEGMENT_LABELS = "segment_labels"
export const SELECTED_SEGMENT_ID = "selected_segment_id"
export const NEEDLE_PERCENTAGE = "needle_percentage"




export const GLOBAL_DEFAULT_PARAMETERS = {
                                          // All charts 
                                          [MARGIN] : { [TOP]: 15, [RIGHT]: 30, [BOTTOM]: 15, [LEFT]: 60 },
                                          [PROPORTIONAL_WIDTH] : 800,
                                          [PROPORTIONAL_HEIGHT] : 260,
                                          [X_LABEL_FONT_SIZE] : 12,
                                          [Y_LABEL_FONT_SIZE] : 12,
                                          [X_LABEL] : "",
                                          [Y_LABEL] : "",
                                          [Y_PADDING] : 0.05,
                                          [X_PADDING] : 0.05,
                                          [TITLE] : "",
                                          [TITLE_FONT_SIZE] : 15,
                                          [SECOND_Y_AXIS] : false,
                                          [SECOND_Y_LABEL] : "",
                                          [INCLUDE_BORDER] : false,

                                          // Line charts
                                          [NORMAL_WIDTH] : 2.5,
                                          [ENFASIS_WIDTH] : 7.5,
                                          [CONTINUOS_LINE_NAME] : "",
                                          [CATEGORICAL_LINE_NAME] : "",
                                          [INCLUDE_CATEGORCIAL_LINE] : true,
                                          [INCLUDE_CONTINOUS_LINE] : true,
                                          [CONTINUOS_LINE_COLOR] : "var(--color-2)",
                                          [CATEGORICAL_LINE_COLOR] : "var(--color-1)",

                                          // Bar constants
                                          [BARS_COLOR] : "var(--color-2)",
                                          [BARS_NAME] : "",
                                          
                                          // Opacity
                                          [FULL_OPACITY] : 1,
                                          [HIDDEN_OPACITY] : 0.2,
                                          [MIN_OPACITY] : 0.5,
                                          // Legend
                                          [INCLUDE_LEGEND] : true,
                                          [LEGEND_HEIGHT] : 0.15, // Percentage
                                          [LEGEND_MARGIN_FROM_CHART] : 0.08, // Percentage
                                          [LEGEND_SELECT_SHIFT] : 30,                                          
                                          [LEGEND_NUM_ROWS] : 3,
                                          [LEGEND_CIRCLE_RADIUS] : 5,
                                          [LEGEND_PADDING]: 2,
                                          [ENFASIS_WEIGHT] : 900,
                                          [NORMAL_WEIGHT] : 20,
                                          
                                          
                                          // Animation
                                          [DATA_CHANGE_TRANSITION_TIME] : 1500,
                                          [ANIMATION_TIME] : 700}



// Dates in Spanish
export const  es_ES = {
    "decimal": ",",
    "thousands": ".",
    "grouping": [3],
    "currency": ["€", ""],
    "dateTime": "%a %b %e %X %Y",
    "date": "%d/%m/%Y",
    "time": "%H:%M:%S",
    "periods": ["AM", "PM"],
    "days": ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
    "shortDays": ["Dom", "Lun", "Mar", "Mi", "Jue", "Vie", "Sab"],
    "months": ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
    "shortMonths": ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"]
};
